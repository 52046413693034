.container {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  width: 41.8rem;
}

.buttons-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* justify-content: space-between; */
  gap: 0.8rem;
}
