.resulting-datatable-container {
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
  margin-left: 3.2rem;
  margin-right: 3.2rem;
  padding: 1rem 0;
  overflow-x: auto;
  width: calc(100vw - 57.2rem);
}

.table {
  border-collapse: collapse;
  width: 100%;
}

.table td {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}

.head-row {
  font-size: 1.4rem;
  line-height: 2rem;
  color: var(--color-gray900);
  font-weight: 500;
  text-align: left;
  border-bottom: 1px solid var(--color-gray100);
}

.body-row {
  height: 3.2rem;
  text-align: left;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 400;
  color: var(--color-gray700);
}

.head-cell {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  height: 4.4rem;
}

.head-cell-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.4rem;
}

.head-cell-title {
  white-space: nowrap;
}

.table td:first-child,
.table th:first-child {
  position: sticky;
  left: 0;
  background-color: var(--color-white);
  z-index: 2;
  padding-right: 3.2rem;
}

.table td:first-child:before,
.table th:first-child:before {
  box-sizing: border-box;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0.2rem;
  display: block;
  height: 100%;
  box-shadow: rgba(17, 17, 26, 0.1) 0.1rem 0rem 0rem;
}
