.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;
}

.spinner {
  width: 2.2rem;
  height: 0.6rem;
}
