.content-container {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  min-height: 30rem;
  min-width: 91.2rem;

  @media (max-width: 1024px) {
    padding: 2.4rem 1.6rem 0 1.6rem;
  }
}
