.builder-container {
  min-height: 100vh;
  display: grid;
  grid-template-columns: 28rem auto;
  overflow-y: auto;
}

.preview {
  position: relative;
  width: 100%;
  padding-top: 2.4rem;
}

.prevew-content-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.img-distance {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2.4rem;
}
